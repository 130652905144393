$(function() {
    $('.rightTalent').waypoint(function(direction) {
        setTimeout(function(){ $('.rightTalent div img:nth-child(1)').addClass('animated fadeInUp'); }, 600);
        setTimeout(function(){ $('.rightTalent div img:nth-child(2)').addClass('animated fadeInUp'); }, 1000);
        setTimeout(function(){ $('.rightTalent div img:nth-child(3)').addClass('animated fadeInLeft'); }, 100);
        setTimeout(function(){ $('.rightTalent div img:nth-child(4)').addClass('animated fadeInRight'); }, 100);
        setTimeout(function(){ $('.rightTalent div img:nth-child(5)').addClass('animated slideInLeft'); }, 700);
    }, { offset: '90%' });
    $('.integrate').waypoint(function(direction) {
        $('.integrate .cogs').addClass('active');
    }, { offset: '70%' });
});
