upstack.filter('unique', function(){
    return function(collection, keyname) {
		var output = [],
			keys = [];
		
		angular.forEach(collection, function(item) {
			var key = item[keyname];
			if(keys.indexOf(key) === -1) {
				keys.push(key);
				output.push(item);
			}
		});
		
		return output;
	};
});

upstack.directive('toggleActive', function () {
	return {
		restrict: 'A',
		link: function (scope, element) {
			var onElementClick = function () {
				if (element.hasClass('active'))
					element.removeClass('active');
				else
					element.addClass('active');
			};
			angular.element(document).on('click', function (event) {
			    if(event.target.tagName !== 'LI') {
			        angular.element(document.querySelector('.tabs')).removeClass('active');
			    }
			});
			
			angular.element(element).on('click', onElementClick);
			
			scope.$on('$destroy', function () {
				element.off('click', onElementClick);
			});
		}
	};
});

upstack.controller('faqCtrl', ['$filter', '$sce', '$timeout', '$location', function ($filter, $sce, $timeout, $location) {
	var vm = this;
	
    vm.highlight = function(search, text){
        if (!search)
            return $sce.trustAsHtml(text);
            
        return $sce.trustAsHtml(text.replace(new RegExp(search, 'gi'), '<span class="highlight">$&</span>'));
    };

    vm.setCategory = function(category) {
        vm.category = category;
        vm.label = category? category.label: undefined;
        window.location.hash = category? '#' + category.label: '';
        if(!category)
            history.pushState("", document.title, window.location.pathname + window.location.search);
    };

	vm.faqs = [
		{
			category: 'Overview',
			question: {
				title: 'What is Upstack?',
				answer: 'Upstack is global network of vetted and tested engineering talent.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'What exactly does Upstack offer?',
				answer: 'We provide access to full-time dedicated engineering talent available to join your team on-demand.  Our talent works remotely for your company while Upstack handles the HR, payroll,  project management, QA, and support.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'Are there any upfront fees or recruiting costs?',
				answer: 'We’re not recruiters so there are no recruiter fees.  We simply charge a $399 deposit once you’re ready to get started with your engineer and deduct from your first invoice.  If you decide you do not want to continue beyond the trial period you will be refunded in full.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'How does the risk-free trial work?',
				answer: 'Every client is given a full 2 weeks in which to evaluate working with an Upstack engineer and ensure that the engagement will be successful.  If you’re satisfied, you will be bill as usual for the first weeks. However, if for any reason you are not satisfied you won’t be billed.  From there, we can either part ways or you can engage with another Upstack engineer who will begin another trial period.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'Are your engineers full-time?',
				answer: 'Yes.  Our engineers typically work 40 hours per week.  In some instances, we may be able to offer part-time engineers but it is on a case-by-case basis.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'What level of expertise do your engineers have?',
				answer: 'Every Upstack engineer is senior level and verified to have at least 3 years of documented experience in their specific language of expertise.  We don’t offer junior or mid-level engineers (though our cost for a senior level is very comparable)'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'What happens if I’m not satisfied with an engineer?',
				answer: 'Assuming you are no longer in the trial period,  we would first want to be notified of any issues you may be having and try and work them out.  If for some reason we can’t,  then you will need to give the engineer 2 weeks notice.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'What is the advantages of working with Upstack over comparable services?',
				answer: 'Due to lack of screening,  it has become incredibly difficult to find reliable and high caliber engineering talent.  We’ve developed a smart infrastructure and intense screening process to find only the best talent the world has to offer. However, talent is just one part of what makes us stand out.  We also provide management of your remote team allowing you to focus more on the business while we handle all the things going on behind the scenes to make sure that your team is working a maximum efficiency.'
			}
		},
		{
			category: 'Overview',
			question: {
				title: 'How is Upstack different than a standard development shop?',
				answer: 'We offer something very different than what you would get from a development shop.  A typical development shop will offer you a project estimate and then outsource your project to a team of engineers that may not stay the same throughout the duration of the project.  Additionally, you may only have limited or zero direct contact with your team instead relying on a single contact person to provide updates.  With Upstack,  you get a dedicated engineer who is interacts with your team just in the same way an in-house team member would.  You have direct contact with them and they are working exclusively for your company - all for a weekly flat rate.'
			}
		},
		{
			category: 'Screening Process',
			question: {
				title: 'Do all of your engineers speak fluent English?',
				answer: 'Yes, absolutely.  Every Upstack engineer writes and speaks English fluently.  In fact, before we even begin with any technical testing we put potential candidates through a series of interviews to make sure they are excellent communicators and have the type of personalities are suited for working with western teams.'
			}
		},
		// {
		// 	category: 'Screening Process',
		// 	question: {
		// 		title: 'How do you find your talent?',
		// 		answer: 'Lorem ipsum dolor sit amet'
		// 	}
		// },
		{
			category: 'Screening Process',
			question: {
				title: 'How do you guarantee that your talent are the top 1%?',
				answer: 'We match each candidate with a senior Upstack expert in their domain.  Then we put each and every candidate through a rigorous eight stage testing process testing for language and communication, cultural fit and personality, and domain specific skills and knowledge.  For each 100 candidates we receive, only 1 will make it through on average and be invited to join our network.'
			}
		},
		{
			category: 'Geography',
			question: {
				title: 'Where is your company based?',
				answer: 'We are headquartered in NYC.  If you’re in the area, stop by our office on Broadway.  We also have a base in LA and Sydney.'
			}
		},
		{
			category: 'Geography',
			question: {
				title: 'Where are your engineers located?',
				answer: 'Upstack engineers are located all around the globe in over 20 countries with a focus on Europe and South America,  however we aren’t bound by physical location when looking for the best talent.'
			}
		},
		{
			category: 'Geography',
			question: {
				title: 'Where do Upstack engineers work?',
				answer: 'The vast majority work from either their home or co-working spaces.  We also have Upstack development hubs in areas with a high concentration of talent.'
			}
		},
		{
			category: 'Geography',
			question: {
				title: 'Can I bring an Upstack engineer on-site?',
				answer: 'Yes, while we make sure it isn’t necessary to be on-site to get maximum productivity out of your Upstack engineer we certainly encourage it should a client choose to do so.  We will work with you to coordinate relocation of your engineer or team for a set period of time.'
			}
		},
		{
			category: 'Geography',
			question: {
				title: 'How does it work being in different time zones?',
				answer: 'Most of our clients are U.S. based so we have quite a bit of experience with working across multiple time-zones.  We make sure that their is significant overlap of time so that your Upstack engineer is available for all daily stand-ups and team meetings.  Additionally, most of our engineers have very flexible schedules and will work on the hours that fit the client’s schedule best.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'How do I go about hiring an Upstack engineer(s)?',
				answer: 'First you are paired with an Account Executive who will get to know your needs.  Following this, we use a mix of smart intelligence and our years of expertise to select the engineers that are the best fit for your team.  You will then be able to review the engineer profiles and set up optional interviews prior to hiring - which you will do through our client portal.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'How does Upstack make it easier to work with a remote team or engineer?',
				answer: 'Typically, the issues working with remote development teams involve reliability, productivity, and quality.  Upstack has solved these issues by creating an infrastructure that includes assigning a client coordinator to manage the engineer or team and keep projects on task and an online portal for tracking the hours and day-to-day workings of your Upstack team.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'What happens if an Upstack engineer is unavailable to work certain days?',
				answer: 'Just like with an internal employee,  things do come up from time to time.  However, we go to every possible length to ensure that this does not happen.  We understand how important dependability is,  and together with your assigned client coordinator we ensure that availability and reliability is a top priority of our engineers.  Should an engineer still not be able to work a certain day,  we will adjust accordingly on your next invoice.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'How long does it take to hire an engineer?',
				answer: 'We have a very extensive network of engineers which will typically allow us to select candidates and share portfolios within 72 hours.  More than 90% of our clients select the first candidate(s) that we select.  Compare this to the 73 days it takes on average to hire an engineer in-house.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'How long is a typical engagement with an Upstack engineer?',
				answer: 'We focus on long-term placements with the majority of our clients hiring our engineers to fill a full-time job, just as they would’ve hired an in-house employee.  Most of the developers in our network are seeking challenging career opportunities, however there is no required engagement period.'
			}
		},
		{
			category: 'Process',
			question: {
				title: 'What other tools does Upstack provide to manage my engineer or team?',
				answer: 'We are constantly innovating and building internal tools to make managing a distributed and remote company a breeze. We have developed a Slackbot that ensures developers provide an overview of the time worked each day, we also have an online portal where you can access timesheets, invoices and other fun stuff.'
			}
		},
		{
			category: 'Cost',
			question: {
				title: 'How much does Upstack cost?',
				answer: 'On average, an engineer with Upstack costs about 50% of hiring an in-house engineer.  Due to the wide range of engineers we have around the world there is some flexibility, but our pricing is always flat rate and incredibly competitive.  Speak with an Account Executive today to discuss in more detail.'
			}
		},
		{
			category: 'Cost',
			question: {
				title: 'How are payment handled? How often will I be charged?',
				answer: 'We invoice our clients every 2 weeks for the previous 2 weeks work.  Invoices are generated,  and can be paid automatically with a credit card on file.'
			}
		},
		{
			category: 'Cost',
			question: {
				title: 'What methods of payments do you accept?',
				answer: 'We accept credit cards, wires and checks. We do not accept Paypal or other digital wallets.'
			}
		},
		{
			category: 'Cost',
			question: {
				title: 'Is there a cancellation fee if stop work with Upstack engineer?',
				answer: 'We require that you give us 2 weeks notice before stopping with any engineer.  As long as this notice is given, we never charge a cancellation fee.'
			}
		},
		{
			category: 'Intellectual Property',
			question: {
				title: 'Who owns the legal rights to the work done by an Upstack engineer?',
				answer: 'The client.  Every bit of intellectual property created during the engagement is owned freely and fully by the client.'
			}
		},
		{
			category: 'Intellectual Property',
			question: {
				title: 'How do we ensure our IP is protected?',
				answer: 'We have a clear contract with both the Client and the Developer to ensure confidentiality and ownership for our Clients company.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Why join the Upstack team?',
				answer: 'It’s fun and challenging to join the top 1% of the world’s developers. We have a strong focus on quality clients and developers and try to work on software that we love!'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Where is Upstack located?',
				answer: 'Nowhere. Everywhere! We are not bound by location and love working remote. Upstack is officially based in New York city and we have offices in NYC and LA. We also have development hubs opening up all around the place with our first in Brasov, Romania. Our developers work from home and co-working spaces and sometimes even on the beach!'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Is it fun to work at Upstack?',
				answer: 'Yes, it’s fun working at Upstack. We are a trusted team of friends who like to communicate, listen, share personal or work-related experience, improve, change the world, get involved in social, think outside the box, get together. We chat daily on our internal Slack channels, we have weekly seminars and monthly raffles. We do regular online workshops or brainstorms to get everyone involved in the work process.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What happens on a day to day basis?',
				answer: 'Once you start working on a client project, you will work as an integrated part of the team. We have daily stand-ups with the client and you will be assigned a Project Coordinator to help ensure that things run smoothly.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Can I apply on a part-time position?',
				answer: 'No. Currently we are only focused on full-time positions.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What is the Upstack interviewing process?',
				answer: 'The hiring process at Upstack has 3 main steps',
				description: [
					'Screening - We will review your application and determine suitability for the Upstack network.',
					'Initial Interview - within 1-2 days you will be invited to the initial interview which will cover overall communication and personality.',
					'Coding Challenge - the next step is a coding challenge focused on tech skills.'
				]
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What happens in an initial interview?',
				answer: 'The initial interview is focused on overall communication and personality fit for the Upstack network. We look at how you present yourself, how prompt you are for the start of the meeting, your internet connection and other essential for remote working.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What happens in a Coding Challenge?',
				answer: 'One of our engineers will run the interview and will focus on the specific skills of the job you are applying for. Expect to screenshare and code in real time and other fun stuff.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What happens after the Coding Challenge?',
				answer: 'You will receive a feedback from us no matter the answer. If the answer is “Yes”, you will be notified within few days. If your profile is not quite what we expect for the moment we will keep in touch for future collaborations.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Can I apply again after a while?',
				answer: 'Sure, you are welcome to apply again after a while.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'How flexible are the working hours at Upstack?',
				answer: 'We are very flexible, as soon as you join the daily stand-ups, do your work and are responsive on the online tools we are using.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'Will I be paid between projects?',
				answer: 'No. This is a freelance position and you only get paid while being involved in a project. Many of our top developers are constantly kept busy with long term engagements or new clients.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What about vacations?',
				answer: 'You need to give 1 month notice prior to vacation so we can organize the things while you will be traveling the world. You will not be paid for the vacation.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'How long does it take to start a project with you?',
				answer: 'There is no estimated time until starting but we are trying our best to find a suitable project for you.'
			}
		},
		{
			category: 'Working at Upstack',
			question: {
				title: 'What happens between projects?',
				answer: 'At this point we work really hard to find you another project to work on so there will be minimum down time. This is also an opportunity for a short vacation, personal projects or other freelance work.'
			}
		}
	];

	vm.faqs.map(function(value, key){
	    vm.faqs[key].label = vm.faqs[key].category.toLowerCase().replace(/\s/g, '-');
	});
	
    if($location.hash()) {
	    vm.category = vm.faqs.find(function(item){
	        return item.label == $location.hash();
	    });
	}

}]);