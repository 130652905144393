upstack.controller('seoCtrl', function(){
    var vm = this;

    var locationBits = location.href.split('/');
    var keyword = locationBits[locationBits.length - 1].toLowerCase();

    vm.title = '';

    var data = {
        android: {
            title: 'Android'
        },
        angular: {
            title: 'Angular'
        },
        css3: {
            title: 'CSS3'
        },
        html5: {
            title: 'HTML5'
        },
        ios: {
            title: 'iOS'
        },
        java: {
            title: 'Java'
        },
        javascript: {
            title: 'Javascript'
        },
        laravel: {
            title: 'Laravel'
        },
        mysql: {
            title: 'MySQL'
        },
        nodejs: {
            title: 'NodeJS'
        },
        node: {
            title: 'NodeJS'
        },
        python: {
            title: 'Python'
        },
        reactjs: {
            title: 'ReactJS'
        },
        ruby: {
            title: 'Ruby'
        },
        symphony: {
            title: 'Symphony'
        },
        ember: {
            title: 'Ember'
        },
        golang: {
            title: 'Go'
        },
        go: {
            title: 'Go'
        }
    };

    if(data.hasOwnProperty(keyword)) {
        vm.title = ' ' + data[keyword].title;
    }

});