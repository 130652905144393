$(function(){
    $('.clients .preview .play').click(function(){
        $('.video').removeClass('active');
        $('.clients iframe').each(function(){
            $(this).attr('src', $(this).attr('src').split('?')[0]);
        });
        
        var video = $(this).parents('.video').find('iframe');
        var src = video.attr('src');
        src += '?autoplay=1';
        video.attr('src', src);
        $(this).parents('.video').addClass('active');
    });
});
