var loginForm = new Vue({
    el: "#login-form",
    data: {
        username: '',
        password: '',
        remember: true,
        errorMessage: '',
        buttonContent: 'Log in'
    },
    methods: {
        animateButton: function() {
            this.buttonContent = '<span class="fa fa-spinner fa-spin"></span>'
        },
        resetButton: function() {
            this.buttonContent = 'Log in'
        },
        showError: function(msg) {
            this.errorMessage = '<span>' + msg + '</span>'
        },
        hideError: function() {
            this.errorMessage = '';
        },
        auth: function() {
            this.hideError();
            this.resetButton();
            if (this.username === "" || this.password === "") {
                this.showError('Username and password are required!');
                return
            }
            const params = new URLSearchParams;
            params.append("username", this.username);
            params.append("password", this.password);
            params.append("remember", this.remember);
            this.animateButton();
            axios.post("https://api.upstack.co/v1/auth", params)
                .then(function(response) {
                    loginForm.resetButton();
                    if (response.data.token) {
                        sessionStorage.setItem('X-API-Token', response.data.token);
                        sessionStorage.setItem('X-API-Stub', response.data.stub)
                    } else {
                        loginForm.showError('An error occurred! Please try again later.');
                        return
                    }
                    if (sessionStorage.getItem('lastVisitedURL')) {
                        location.href = sessionStorage.getItem('lastVisitedURL');
                        return
                    }
                    location.href = "/portal/" + response.data.stub
                }).catch(function(error) {
                loginForm.resetButton();
                loginForm.showError(error.response.data.message ? error.response.data.message : 'An error occurred! Please try again later.');
            })
        }
    }
});