$(function() {
    $('.top1 figure .green').waypoint(function(direction) {
        $('.top1 figure .green').addClass('fadeInUpBig');
        setTimeout(function(){ $('.top1 figure .blue').addClass('fadeInUpBig'); }, 300);
        setTimeout(function(){ $('.top1 figure .red').addClass('fadeInUpBig'); }, 600);
        setTimeout(function(){ $('.top1 figure .lightblue').addClass('fadeInUpBig'); }, 900);
        setTimeout(function(){ $('.top1 figure .yellow').addClass('fadeInUpBig'); }, 1200);
        setTimeout(function(){ $('.top1 figure .user-star').addClass('fadeInUpBig'); }, 1500);
    }, { offset: '90%' });

    $('.topCompanies').waypoint(function(direction) {

        var i = 0;
        $('.topCompanies div').each(function(){
            var self = this;
            setTimeout(function(){
                $(self).find('img').addClass('animated zoomInDown')
            }, i);
            i += 100;
        });
    }, { offset: '90%' });

    $('.worldMap').waypoint(function(direction) {
        setTimeout(function(){ $('.worldMap .green').addClass('active'); }, 300);
        setTimeout(function(){ $('.worldMap .red').addClass('active'); }, 600);
        setTimeout(function(){ $('.worldMap .pink').addClass('active'); }, 900);
        setTimeout(function(){ $('.worldMap .yellow').addClass('active'); }, 1200);
        setTimeout(function(){ $('.worldMap .hq').addClass('active'); }, 1500);
    }, { offset: '70%' });

    $('.worldStats').waypoint(function(direction) {
        stats.forEach(function(item, index){
            counter($(item.name), item.to, 5, 1000, 200, '%', '', '');
        });
    }, {
        offset: '100%'
    });

});
