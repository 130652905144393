upstack.controller('homeCtrl', ['$filter', '$sce', '$timeout', '$scope', function($filter, $sce, $timeout, $scope){
	var vm = this;
	
	vm.imgPath = 'https://s3-us-west-2.amazonaws.com/upstack-assets/img/partners/';
	vm.partners = [
		{
		    name: 'truelocal',
			color: '#2d2d58',
			img: 'truelocal.png',
			video: 'truelocal-video.jpg',
			iframe: 'https://www.youtube.com/embed/qzX4fS-UsiQ?autoplay=1',
			message: 'Upstack can be relied upon to build a future proof website..',
			author: 'KEITH O\'BRIEN & LORETTA CHENG',
			position: 'HEAD OF PRODUCT & DIGITAL PRODUCT PRODUCER',
			group: 1,
			active: false,
			videoActive: false
		},
		{
		    name: 'mingl',
			color: '#fd8023',
			img: 'mingl.png',
			video: 'mingl-video.jpg',
			iframe: 'https://www.youtube.com/embed/lEbAkdyhYwg?autoplay=1',
			message: 'We worked together to create the concept that was Mingl..',
			author: 'OJI UDEZUE',
			position: 'FOUNDER MINGL',
			group: 1,
			active: false,
			videoActive: false
		},
		{
		    name: 'mealpal',
			color: '#0a1a72',
			img: 'mealpal.png',
			video: 'mealpal-video.jpg',
			iframe: 'https://www.youtube.com/embed/5-9YymkgCpk?autoplay=1',
			message: 'We have paying subscribers using the product…',
			author: 'MARY BIGGINS',
			position: 'CO-FOUNDER',
			group: 1,
			active: true,
			videoActive: false
		},
		{
			name: 'gocus',
			color: '#606062',
			img: 'gocus.png',
			video: 'gocus-video.jpg',
			iframe: 'https://www.youtube.com/embed/MiSqsc1U3gw?autoplay=1',
			message: 'Upstack has been really great from going from an idea to an actual product',
			author: 'LEVI GRUNWALD',
			position: 'CO-FOUNDER OF GOCUS',
			group: 2,
			active: false,
			videoActive: false
		},
		{
		    name: 'thechive',
			color: '#66b245',
			img: 'thechive.png',
			video: 'thechive-video.jpg',
			iframe: 'https://www.youtube.com/embed/x4jPN3PzBpI?autoplay=1',
			message: 'They are really just as present as any of our other developers..',
			author: 'PATRICK ROGERS',
			position: 'PROJECT MANAGER',
			group: 2,
			active: true,
			videoActive: false
		},
		{
		    name: 'booodl',
			color: '#6b519e',
			img: 'booodl.png',
			video: 'booodl-video.png',
			iframe: 'https://www.youtube.com/embed/O9tteY0-ZaA?autoplay=1',
			message: 'Without Upstack we would not have had an Android app...',
			author: 'NIMROD GORALI',
			position: 'SENIOR PRODUCT MANAGER OF BOOODL',
			group: 2,
			active: false,
			videoActive: false
		}
	];
    
    vm.trustedCompanies = [
        {
            name: 'mealpal',
            color: '#ff4338',
            img: 'mealpal.png',
            video: 'mealpal-video.jpg',
            iframe: 'https://www.youtube.com/embed/5-9YymkgCpk?autoplay=1',
            message: '“The Upstack team took a concept and turned it into a product”',
            author: 'MEALPAL',
            position: '$35M raised',
            active: true,
            videoActive: false,
            zindex:2
        },
        {
            name: 'thechive',
            color: '#66b245',
            img: 'thechive.png',
            video: 'thechive-video.jpg',
            iframe: 'https://www.youtube.com/embed/x4jPN3PzBpI?autoplay=1',
            message: '“The engineers have fit in great with the team”',
            author: 'THECHIVE',
            position: '100M+ visitors',
            active: false,
            videoActive: false,
            zindex:1
        },
        {
            name: 'truelocal',
            color: '#2d2d58',
            img: 'truelocal.png',
            video: 'truelocal-video.jpg',
            iframe: 'https://www.youtube.com/embed/qzX4fS-UsiQ?autoplay=1&showinfo=0&controls=0&hd=1&mute=1&loop=1&iv_load_policy=3 ',
            message: '“We felt Upstack as an extension of our team”',
            author: 'TRUELOCAL',
            position: '#1 directory in AUS.',
            active: false,
            videoActive: false,
            zindex:1
        },
        {
            name: 'invision',
            color: '#ff3366',
            img: 'invision.png',
            video: 'invision-video.jpg',
            iframe: '',
            message: '“Upstack plays a key role in building the engineering team”',
            author: 'INVISION',
            position: '$230M raised',
            active: false,
            videoActive: false,
            zindex:1,
            videoDisabled: true
        },
        {
            name: 'winc',
            color: '#fff',
            img: 'winc.png',
            video: 'winc-video.jpg',
            iframe: '',
            message: '“Upstack has integrated into the team to help bring wine to their customers”',
            author: 'WINC',
            position: '1M+ customers',
            active: false,
            videoActive: false,
            zindex:1,
            videoDisabled: true
        }
    ];

    vm.setActiveCompany = function(key) {
        for(k in vm.trustedCompanies) {
            if(vm.trustedCompanies[k].active)
                vm.trustedCompanies[k].zindex = 2;
            else
                vm.trustedCompanies[k].zindex = 1;
            vm.trustedCompanies[k].active = false;
            vm.trustedCompanies[k].videoActive = false;
            
            var video = document.querySelector('.index' + k + ' video');
            if(!vm.trustedCompanies[k].videoDisabled) {
                video.pause();
                video.currentTime = 0;
            }
        }
    
        vm.trustedCompanies[key].zindex = 3;
        vm.trustedCompanies[key].active = true;
    };

    vm.stopVideo = function(key) {
        vm.trustedCompanies[key].videoActive = false;
        var video = document.querySelector('.index' + key + ' video');
        video.pause();
        video.currentTime = 0;
    }

	vm.setActive = function(partner) {
	    var group = $filter('filter')(vm.partners, { group: partner.group }, true);
	    angular.forEach(group, function(v, k){
	        group[k].active = partner.name == group[k].name;
	    });
	    angular.forEach(vm.partners, function(v, k){
           vm.partners[k].videoActive = false;
	    });
	};

	vm.playVideo = function(key) {
	    for(k in vm.trustedCompanies)
	        vm.trustedCompanies[k].videoActive = false;
        
        vm.trustedCompanies[key].videoActive = true;
        
        $scope.$$postDigest(function(){
            document.querySelector('.index' + key + ' video').play();
        });
	};

	vm.startVideo = function(partner) {
	    angular.forEach(vm.partners, function(v, k){
	        if(partner.name == v.name)
	           vm.partners[k].videoActive = true;
            else
               vm.partners[k].videoActive = false;
	    });
	};
    
    vm.trustUrl = function(iframe) {
	   return $sce.trustAsResourceUrl(iframe);
    }
	
}]);

var elements = [
// Home
    $('.globalTalent'),
    $('.intelligentTalent'),
    $('.quickOnboarding'),
    $('.topDevelopers'),
    $('.teamManagement'),
    $('.kpi'),
// Talent
    $('.worldStats'),
    $('.topCompanies'),
    $('.worldMap'),
    $('.top1'),
    $('.worldMap')
];

var stats = [
    {name:'.worldStats .eastern', to: 40},
    {name:'.worldStats .central', to:20},
    {name:'.worldStats .south', to:20},
    {name:'.worldStats .usa', to:15},
    {name:'.worldStats .other', to:5}
];

var developers = $('.developers');
var interviews = $('.interviews');
var countries = $('.countries');

var engineers = $('.engineers');
var clients = $('.clients');
var raised = $('.raised');
var counting = {};

function counter(el, to, ease, time, delay, firstSuffix, suffix, prefix) {
    var i = 0;
    var easing = function(){
        if(!!(ease%1)) {
            i = (to-ease)+0.01;
        }
        else
            i = to-ease+1;
        
        var effect = setInterval(function(){
            if(i <= to) {
                el.text(prefix + (!!(ease%1)?i.toFixed(2):i) + firstSuffix + suffix);
                i = !!(ease%1)? (i+0.01): (i+1);
            } else {
                clearInterval(effect);
                el.text(el.text())
            }
        }, (!!(ease%1)?100:delay));
    };
    
    if(!counting[el.selector.replace('.', '')]) {
        var timeout = setInterval(function(){
            if(i <= to-ease) {
                el.text(prefix + (!!(ease%1)?i.toFixed(2):i) + firstSuffix + suffix);
                i = !!(ease%1)? (i+(to > 4? 0.04:0.02)): (i+(to > 600? 21:1));
            } else {
                clearInterval(timeout);
                easing();
            }
        }, (!!(ease%1)?20:time/(to-ease)));
        counting[el.selector.replace('.', '')] = true;
    }
}

$(function(){

	if($(document).scrollTop() > 154) {
		$('body').addClass('fixed');
	}

    var kpiTop = $('.kpi').length ? parseInt($('.kpi').offset().top) : 0;
    var wh = $(window).height();

    $(document).scroll(function(){
        var scrollTop = $(this).scrollTop();

        if(scrollTop > 154) {
            $('body').addClass('fixed');
        } else {
            $('body').removeClass('fixed');
        }
    });

    

    $('.owl-carousel').owlCarousel({
        loop:true,
        margin:0,
        items:1,
        pagination:false,
        navigation:true
    });

    $('.icon-menu').click(function(){
        $('body').addClass('menuActive');
    });
    $('.leftMenu .icon-close, .overlayer').click(function(){
        $('body').removeClass('menuActive');
    });

    $('.globalTalent').waypoint(function(direction) {
        $('.talents article').removeClass('active');
        $('.globalTalent').addClass('active');
    }, {
        offset: '50%'
    });
    $('.intelligentTalent').waypoint(function(direction) {
        $('.talents article').removeClass('active');
        $('.intelligentTalent').addClass('active');
    }, {
        offset: '50%'
    });
    $('.quickOnboarding').waypoint(function(direction) {
        $('.talents article').removeClass('active');
        $('.quickOnboarding').addClass('active');
    }, {
        offset: '50%'
    });
    $('.topDevelopers').waypoint(function(direction) {
        $('.talents article').removeClass('active');
        $('.topDevelopers').addClass('active');
    }, {
        offset: '50%'
    });
    $('.teamManagement').waypoint(function(direction) {
        $('.talents article').removeClass('active');
        $('.teamManagement').addClass('active');
    }, {
        offset: '50%'
    });

    $('.kpi').waypoint(function(direction) {
        counter(developers, 650, 5, 500, 200, '', '', '');
        counter(interviews, 6800, 10, 500, 200, '', '', '');
        counter(countries, 26, 5, 500, 200, '', '', '');

        counter(engineers, 650, 5, 100, 100, '', '', '');
        counter(clients, 120, 5, 500, 100, '', '', '');
        counter(raised, 1.40, 0.10, 500, 200, 'B', '', '$');
    }, {
        offset: '100%'
    });
    
    var i = {
            left: 1,
            right: 1,
            'child-left': 1,
            'child-right': 5
        },
        step = 22.5;
    function transform(data, i, step, scale){
        return {
            '-webkit-transform': 'rotate3d(0,0,1,' + (data - i*step) + 'deg) scale3d(' + scale + ',' + scale + ',' + scale + ')',
            '-moz-transform': 'rotate3d(0,0,1,' + (data - i*step) + 'deg) scale3d(' + scale + ',' + scale + ',' + scale + ')',
            '-ms-transform': 'rotate3d(0,0,1,' + (data - i*step) + 'deg) scale3d(' + scale + ',' + scale + ',' + scale + ')',
            '-o-transform': 'rotate3d(0,0,1,' + (data - i*step) + 'deg) scale3d(' + scale + ',' + scale + ',' + scale + ')',
            'transform': 'rotate3d(0,0,1,' + (data - i*step) + 'deg) scale3d(' + scale + ',' + scale + ',' + scale + ')'
        };
    }
    function transformHeader(i, step){
        return {
            '-webkit-transform': 'rotate3d(0,0,1,' + (i*step) + 'deg)',
            '-moz-transform': 'rotate3d(0,0,1,' + (i*step) + 'deg)',
            '-ms-transform': 'rotate3d(0,0,1,' + (i*step) + 'deg)',
            '-o-transform': 'rotate3d(0,0,1,' + (i*step) + 'deg)',
            'transform': 'rotate3d(0,0,1,' + (i*step) + 'deg)'
        };
    }
    function rotate(side){
        setTimeout(function(){ $('.lines').addClass('active'); }, 1000);
        setTimeout(function(){ $('.lines').removeClass('active'); }, 1300);
        $('.headerBg').find('.' + side).css(transformHeader(i[side], step));
        $.each($('.' + side + ' img'), function(k){
            if(k == (16-i['child-' + side])) $(this).css(transform($(this).data('rotate'), i[side], step, 1)).addClass('active');
            else $(this).css(transform($(this).data('rotate'), i[side], step, 0)).removeClass('active');
        });
        if(i['child-' + side] == 16)
            i['child-' + side] = 1;
        else i['child-' + side]++;
        i[side]++;
    }

    rotate('left');
    rotate('right');
    setInterval(function(){
        rotate('left');
        rotate('right');
    }, 2000);
    
    $('.playBtn').click(function(){
        var src = $(this).data('src');
        $(this).parent().hide().parent().find('iframe').addClass('active').attr('src', src);
    });


//     shapes beta homepage

    $('.join-team').waypoint(function(direction) {
        $('.join-team').addClass('active');
    }, { offset: '80%' });
    $('.intelligent').waypoint(function(direction) {
        $('.intelligent').addClass('active');
    }, { offset: '80%' });
    $('.top1p').waypoint(function(direction) {
        $('.top1p').addClass('active');
    }, { offset: '80%' });
    $('.flexible-contracts').waypoint(function(direction) {
        $('.flexible-contracts').addClass('active');
    }, { offset: '80%' });
    $('.right-technology').waypoint(function(direction) {
        $('.right-technology').addClass('active');
    }, { offset: '80%' });

});
