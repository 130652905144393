upstack.controller('jobsCtrl', ['$filter', '$http', '$sce', '$timeout', function($filter, $http, $sce, $timeout){
	var vm = this;
	
	vm.loading = true;
    vm.jobs = [];

    $http.get('https://api.upstack.co/v1/jobs/openings').then(function(response){
        vm.loading = false;
        vm.jobs = response.data.job_openings;
        vm.jobs = vm.jobs.map(function(item){
            item.created_at = moment(item.created_at, 'YYYY-MM-DD');
            item.created_at = moment(item.created_at).format('DD MMMM YYYY');
            item.description = item.description.replace(/\n/g, '<br/>');
            item.description = $sce.trustAsHtml(item.description);
            item.token = item.gh_code;
            return item;
        });
    });

	vm.close = function(){
	    vm.jobData = undefined;
	};
	
}]);

$(function(){

    $('.pics .img').click(function(){
        if($(this).hasClass('active')) {
            $('.pics .img').removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

});

function resizeIframe(iframe) {
    iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
}